import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoadingComponent } from "./features/commons/loading/loading.component";
import { StorageService } from './core/services/storage.service';

@Component({
  selector: 'app-root',
  standalone: true,
  providers:[StorageService],
  imports: [RouterOutlet, LoadingComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'ceveo-front';

  constructor(private storageService : StorageService) {
    const role = this.storageService.getItem('role');
    if(!role) {
      this.storageService.setItem('role' , 'C');
    }
  }
}
