import { Component } from '@angular/core';
import { LoadingAnimationService } from '../../../core/services/loading-animation.service';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent {
  show: boolean = false;

  constructor(private las: LoadingAnimationService) {}

  ngOnInit(): void {
    this.las.loading.subscribe((show: boolean) => {
      this.show = show;
    });
  }
}
